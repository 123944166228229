import actions from '../redux/game/actions';
import actionsModals from '../redux/modals/actions';
import ControllerSounds, { eSoundType } from '../controllers/sounds/controllerSounds';
import ControllerMock from './controllerMock';
import { store } from '../index';
import eModalType from '../controllers/popups/eModalType';
import selectors from '../redux/game/selectors';
import { eGameState } from '../redux/game/reducer';

const enableMock = false;

const initManagerParams = {
  developmentGetSessionPath: 'https://int.dev.onlyplay.net/integration/request_frame/?game=junglegold&no_redirect&no_op_launcher',
  cheatsParams:  ['force_reels', 'force_freespins'],
  baseData: {
    ver: '1.1'
  }
};

export const requestConfig = async () => {
  window.OPWrapperService.serverManager.initManagerGameRequests(initManagerParams);
  await window.OPWrapperService.serverManager.sendGameInitRequest();

  const response = enableMock ?
    await ControllerMock.requestConfig() :
    await sendRequest({ }, 'config');

  response.available_icons = ControllerMock.availableSymbols;

  window.OPWrapperService.freeBetsController.setData(response.freebets);
  store.dispatch(actions.setData(response));
};

export const requestNewGame = async () => {
  const state = store.getState();
  const amount = selectors.getBetAmount(state);
  const betIndex = selectors.getBetIndex(state);
  const balance = selectors.getBalance(state);

  if (!state.game.freespinsEnabled && !window.OPWrapperService.freeBetsController.isActive) {
    store.dispatch(actions.setBalance(balance - amount));
  }

  const data = {
    betIndex,
  };

  let response = enableMock ?
    await ControllerMock.requestNewGame(amount) :
    await sendRequest(data, 'new_game');

  if (response.error) {
    store.dispatch(actionsModals.showModal({ type: eModalType.EMT_INFO, data: response.message }));
    store.dispatch(actions.setGameState(eGameState.EGS_CAN_STOP));
  } else {
    window.OPWrapperService.freeBetsController.setData(response.freebets);
    store.dispatch(actions.setNewGame(response));
  }
};

const sendRequest = async (data, id, urlPath = 'game_request') => {
  data.id = id;
  return await window.OPWrapperService.serverManager.sendGameRequest(data, urlPath);
};
